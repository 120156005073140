import React from 'react'
import Cardcopy from '../components/Cardcopy'
import Review from '../components/Review'
import Map from '../components/Map'
import SelectProduct from '../components/SelectProduct'
import { AppContext } from '../App'
import useStore from '../store'

function Home({ checkProduct }) {
    const {
        Swiper,
        SwiperSlide,
        Autoplay,
        Pagination,
        Navigation,
        EffectFade,
    } = React.useContext(AppContext)

    const { products } = useStore()
    const progressCircle = React.useRef(null)
    const progressContent = React.useRef(null)
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress)
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
    }

    return (
        <>
            <div className="home styleBlock">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    effect={'fade'}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: false,
                    }}
                    navigation={false}
                    modules={[EffectFade, Autoplay, Pagination, Navigation]}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    className="mySwiper"
                >
                    <SwiperSlide className="mySlider">
                        <p>В продаже уголь марок ДР, ДПК, ДПКО</p>
                        <img
                            src="./img/ugol.png"
                            alt="слайдер В продаже уголь марок ДР, ДПК, ДПКО"
                        />
                    </SwiperSlide>
                    <SwiperSlide className="mySlider">
                        <p>Уголь каменный в мешках 25кг, 50кг</p>
                        <img
                            src="../img/meshki.png"
                            alt="слайдер Уголь каменный в мешках 25кг, 50кг"
                        />
                    </SwiperSlide>
                    <SwiperSlide className="mySlider">
                        <p>
                            Приобретая уголь на наших складах, Вы имеете
                            возможность получить компенсацию до 100% за покупку
                            угля и его доставку
                        </p>
                        <img
                            src="../img/many.png"
                            alt="Вы имеете возможность
                        получить компенсацию до 100% за покупку угля и его
                        доставку"
                        />
                    </SwiperSlide>
                    <div className="autoplay-progress" slot="container-end">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>
                </Swiper>
                <div className="headerBlock">
                    <div className="headerBlockTop">
                        <h2>Товар</h2>
                        <img width={60} src="./svg/shopping.svg" alt="карта" />
                    </div>
                </div>
                <div className="cards">
                    {products.map(card => (
                        <Cardcopy
                            index={card.id}
                            name={card.name}
                            price={card.price}
                            imgUrl={card.imgUrl}
                            quantity={card.quantity}
                        />
                    ))}
                </div>
                {checkProduct && <SelectProduct />}
            </div>
            <Review />
            <Map />
        </>
    )
}
export default Home
