import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React, { useEffect, createContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules'
import useStore from './store'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import './stylesSwiper.scss'
import 'swiper/css'
import Home from './pages/Home'
import Header from './components/Header'
import Footer from './components/Footer'
import Contacts from './components/Contacts'
import DeliveryForm from './pages/DeliveryForm'

export const AppContext = createContext({})

function App() {
    const [contactOpened, setContactOpened] = React.useState(false)
    const { products, fetchProducts } = useStore()

    useEffect(() => {
        fetchProducts()
    }, [fetchProducts])

    const productAvailable = products.some(product => product.quantity > 0)

    const arraySelectProduct = products.filter(product => product.quantity > 0)
    return (
        <AppContext.Provider
            value={{
                Swiper,
                SwiperSlide,
                EffectFade,
                Autoplay,
                Pagination,
                Navigation,
            }}
        >
            <div className="wrapper">
                {contactOpened && (
                    <Contacts onClickCart={() => setContactOpened(false)} />
                )}

                <Header onClickCart={() => setContactOpened(true)} />
                <Routes>
                    <Route
                        path="/"
                        element={<Home checkProduct={productAvailable} />}
                    />
                    <Route
                        path="/delivery-form"
                        element={
                            <DeliveryForm
                                arrSelect={arraySelectProduct}
                                checkProduct={productAvailable}
                            />
                        }
                    />
                </Routes>

                <Footer />
            </div>
        </AppContext.Provider>
    )
}

export default App
