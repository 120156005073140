import React from 'react'
import MapList from './MapList'
function Map() {	
	return (
		<div className='styleBlock'>
			<div className='headerBlock'>
				<div className='headerBlockTop'>
					<h2>Место нахождения</h2>
					<img width={60} src='./svg/map.svg' alt='карта' />
				</div>
			</div>
			<MapList/>
		</div>
	)
}

export default Map