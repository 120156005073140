import React from 'react'
import { FaPhoneVolume } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";

function Contacts({ onClickCart }) {
	return (
		<div className='rightContact'>
			<div className='close'>
				<FaXmark onClick={onClickCart} style={{ color: "#617a87", fontSize: "48" }} />
			</div>
			<div className='contacts'>
				<ul>
					<li>
						<FaPhoneVolume style={{ color: "#617a87", fontSize: "18", marginRight:"10" }} />
						<span>Телефон:</span> <a href='tel:83835533197'>83835533197</a></li>
					<li>
						<FaEnvelope style={{ color: "#617a87", fontSize: "18", marginRight:"10" }} />
						<span>e-mail:</span> <a href='mailto:karasuk@ntknso.ru'>karasuk@ntknso.ru</a></li>
					<li><address>г Карасук, ул Дещенко 45. Время работы ПН - ПТ с 09.00 до 17.00</address></li>
				</ul>
			</div>
		</div>

	)
}
export default Contacts