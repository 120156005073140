import React from 'react'
import { Link } from 'react-router-dom'

function SelectProduct() {
    return (
        <div>
            <Link to="/delivery-form">
                <button className="order-button buttonStyle">
                    Посмотреть выбранные товары
                </button>
            </Link>
        </div>
    )
}

export default SelectProduct
