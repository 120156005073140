import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import InputMask from 'react-input-mask'
import useStore from '../store'
import ButtonMinusPlus from '../components/ButtonMinusPlus'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const DeliveryForm = ({ arrSelect, checkProduct }) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [errors, setErrors] = useState({})
    const [hasOrderSent, setHasOrderSent] = useState(false)
    const { deleteProducts } = useStore()
    const [token, setToken] = useState(null)
    const captchaRef = useRef(null)

    useEffect(() => {
        if (token) {
            console.log(`hCaptcha Token: ${token}`)
        }
    }, [token])

    const sendEmail = event => {
        event.preventDefault()
        setErrors({}) // сброс ошибок

        if (!name) {
            setErrors(prev => ({ ...prev, name: 'Имя обязательно' }))
            return
        }
        if (!phone) {
            setErrors(prev => ({ ...prev, phone: 'Телефон обязателен' }))
            return
        }
        if (!token) {
            setErrors(prev => ({
                ...prev,
                captcha: 'Пожалуйста, пройдите капчу',
            }))
            return
        }

        const emailData = {
            name,
            phone,
            products: arrSelect,
            message: `<h1>Новый заказ</h1>
                       <p>Имя: ${name}</p>
                       <p>Телефон: ${phone}</p>
                       <h2>Товары:</h2>
                       <ul>
                           ${arrSelect
                               .map(product => `<li>${product.name}</li>`)
                               .join('')}
                       </ul>`,
            token, // Можно отправить токен, если сервер требует его валидации
        }

        axios
            .post('http://77.222.46.177:5000/send-email', emailData)
            .then(response => {
                setHasOrderSent(true)
                setName('')
                setPhone('')
                deleteProducts()
                setToken(null) // Сбросить токен после отправки
            })
            .catch(error => alert('Ошибка: ' + error.message))
    }

    return (
        <div className="deliveryForm styleBlock">
            {hasOrderSent ? (
                <>
                    <h3>Заказ успешно отправлен</h3>
                    <Link to="/">перейти на главную</Link>
                </>
            ) : (
                <>
                    <h3>Оформление предварительного заказа</h3>
                    <p>
                        Укажите, как к вам можно обращаться и ваш контактный
                        номер телефона.
                    </p>
                    <p>
                        После отправки заказа мы перезвоним вам для
                        подтверждения.
                    </p>
                    <div className="selectProduct">
                        {arrSelect.map((product, index) => (
                            <div className="selectProductItem" key={index}>
                                <p>
                                    {product.name},{' '}
                                    {product.price > 1000
                                        ? 'вес в тоннах'
                                        : 'количество мешков'}{' '}
                                    {product.quantity}
                                </p>
                                <ButtonMinusPlus
                                    quantity={product.quantity}
                                    index={product.id}
                                />
                            </div>
                        ))}
                    </div>
                    {checkProduct ? (
                        <form onSubmit={sendEmail}>
                            <div className="deliveryForm__inputs">
                                <label>
                                    <div>Имя:</div>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        required
                                    />
                                </label>
                                {errors.name && (
                                    <span style={{ color: 'red' }}>
                                        {errors.name}
                                    </span>
                                )}
                            </div>

                            <div>
                                <label>
                                    <div>Номер телефона:</div>
                                    <InputMask
                                        mask="+7 (999) 999-9999"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        placeholder="+7 (___) ___-____"
                                    >
                                        {inputProps => (
                                            <input
                                                {...inputProps}
                                                type="tel"
                                                id="phone"
                                            />
                                        )}
                                    </InputMask>
                                </label>
                                {errors.phone && (
                                    <span style={{ color: 'red' }}>
                                        {errors.phone}
                                    </span>
                                )}
                            </div>
                            <HCaptcha
                                sitekey="9b062e0e-d8dc-42c8-9072-ac54c5b6bdc2" // Замените на ваш реальный ключ
                                onVerify={setToken}
                                ref={captchaRef}
                            />
                            {errors.captcha && (
                                <span style={{ color: 'red' }}>
                                    {errors.captcha}
                                </span>
                            )}
                            <button
                                disabled={!name || !phone || !token}
                                className={
                                    !name || !phone || !token
                                        ? 'buttonNotActive'
                                        : 'buttonStyle'
                                }
                                type="submit"
                            >
                                Отправить
                            </button>
                        </form>
                    ) : (
                        <>
                            <p>
                                Вы удалили все выбранные товары. Заказ не может
                                быть оформлен.
                            </p>
                            <Link to="/">перейти на главную</Link>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default DeliveryForm
