import React from 'react'
import { Link } from 'react-router-dom'
import { FaPhoneVolume } from 'react-icons/fa6'
import { FaEnvelope } from 'react-icons/fa6'
import { FaAlignJustify } from 'react-icons/fa6'

function Header({ onClickCart }) {
    return (
        <div className="header styleBlock">
            <div className="topLine">
                <Link to="/">
                    <img src="../img/logo.png" />
                </Link>

                <div className="name">
                    <span>Новосибирская топливная корпорация</span>
                    <h1>ОСП Карасукский райтоп</h1>
                </div>
                <FaAlignJustify
                    onClick={onClickCart}
                    className="buttonCont"
                    style={{ color: '#617a87', fontSize: '48' }}
                />
                <div className="contacts">
                    <ul>
                        <li>
                            <FaPhoneVolume
                                style={{ color: '#617a87', fontSize: '18' }}
                            />
                            <span>Телефон:</span>{' '}
                            <a href="tel:83835533197">83835533197</a>
                        </li>
                        <li>
                            <FaEnvelope
                                style={{ color: '#617a87', fontSize: '18' }}
                            />
                            <span>e-mail:</span>{' '}
                            <a href="mailto:karasuk@ntknso.ru">
                                karasuk@ntknso.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <address>
                г Карасук, ул Дещенко 45 Время работы ПН - ПТ с 09.00 до 17.00
            </address>
        </div>
    )
}
export default Header
