import { create } from 'zustand'
import axios from 'axios'

const useStore = create(set => ({
    products: [],
    loading: false,
    error: null,
    fetchProducts: async () => {
        set({ loading: true, error: null })
        try {
            const response = await axios.get(
                'https://891c8dfa5070cc69.mokky.dev/items'
            )
            const savedProducts =
                JSON.parse(localStorage.getItem('products')) || []

            const productsWithCount = response.data.map(product => {
                const savedProduct = savedProducts.find(
                    p => p.id === product.id
                )
                return {
                    ...product,
                    quantity: savedProduct ? savedProduct.quantity : 0,
                }
            })

            set({ products: productsWithCount, loading: false })
        } catch (error) {
            set({ error: error.message, loading: false })
        }
    },
    increaseQuantity: productId => {
        set(state => {
            const updatedProducts = state.products.map(product =>
                product.id === productId
                    ? { ...product, quantity: product.quantity + 1 }
                    : product
            )

            // Сохраняем обновлённый массив продуктов в localStorage
            localStorage.setItem('products', JSON.stringify(updatedProducts))
            return { products: updatedProducts }
        })
    },

    decreaseQuantity: productId => {
        set(state => {
            const updatedProducts = state.products.map(product =>
                product.id === productId
                    ? {
                          ...product,
                          quantity: Math.max(product.quantity - 1, 0),
                      } // Избегаем отрицательных значений
                    : product
            )

            // Сохраняем обновлённый массив продуктов в localStorage
            localStorage.setItem('products', JSON.stringify(updatedProducts))
            return { products: updatedProducts }
        })
    },
    deleteProducts: productId => {
        set(state => {
            const updatedProducts = state.products.map(product =>
                product.quantity > 0
                    ? { ...product, quantity: (product.quantity = 0) }
                    : product
            )
            localStorage.setItem('products', JSON.stringify(updatedProducts))

            return { products: updatedProducts }
        })
    },
}))

export default useStore
