import React from 'react'

function Footer() {
	return (
		<footer>
			<div className='styleBlock'>
				<span>НТК ОСП Карасукский райтоп</span>
			</div>
		</footer>

	)
}
export default Footer