import React from 'react'
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';



function MapList() {
	React.useEffect(() => {
		const mapElement = document.querySelector('.ymaps-2-1-79-map');
		if (mapElement) {
			mapElement.addEventListener('wheel', e => {
				e.preventDefault();
			});
		}
	}, []);
	return (
		<div>
			<YMaps>
				<Map defaultState={{ center: [53.732125, 78.078339], zoom: 12, behaviors: ["drag"], controls: [] }} width={'100%'} height={'400px'} >
					<ZoomControl options={{ float: "right" }} />
					<Placemark geometry={[53.732125, 78.078339]} />
				</Map>
			</YMaps>
		</div>
	)
}

export default MapList