import React from 'react'

const Review = () => {
    return (
        <div className="styleBlock">
            <div className="headerBlock">
                <div className="headerBlockTop">
                    <h2>Отзывы покупателей</h2>
                    <img width={60} src="./svg/feedback.svg" alt="карта" />
                </div>
            </div>

            <div className="review">
                <div className="reviewItem">
                    <div className="reviewItemImg">
                        <img
                            width={280}
                            src="./img/saler1.png"
                            alt="отзыв первого покупателя"
                        />
                    </div>
                    <div className="reviewItemText">
                        <h3>Дмитрий Васильевич</h3>
                        <div className="reviewItemText__text">
                            <p>
                                Недавно приобрел уголь для отопления своего дома
                                и хотел бы поделиться своим опытом. Уголь был
                                разных сортов, и после консультации с продавцом
                                выбрал подходящий для моей печи.{' '}
                            </p>
                            <p>
                                Привезли уголь быстро, что также не может не
                                радовать. После нескольких дней использования
                                могу сказать, что уголь действительно оправдал
                                мои ожидания. Он отлично горит, выделяя большое
                                количество тепла. Нагрев в доме стал стабильным,
                                а отопление работает значительно эффективнее,
                                чем раньше.
                            </p>
                            <p>
                                {' '}
                                Также заметил, что зольность действительно
                                низкая, что значительно облегчает процесс
                                очистки. В целом, я остался доволен покупкой.
                                Уголь высокого качества, и я бы рекомендовал его
                                тем, кто ищет надежное решение для отопления.
                            </p>
                            <p>
                                {' '}
                                Также стоит отметить, что цена была более чем
                                приемлемой, особенно с учетом качества. Буду
                                закупаться еще, если возникнет необходимость!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="reviewItem">
                    <div className="reviewItemImg">
                        <img
                            width={280}
                            src="./img/saler2.png"
                            alt="отзыв первого покупателя"
                        />
                    </div>
                    <div className="reviewItemText">
                        <h3>Марина</h3>
                        <div className="reviewItemText__text">
                            <p>
                                Я купила уголь для отопления своего дома, и хочу
                                поделиться своим опытом. Как домохозяйка,
                                которая живет одна, я очень ответственно подошла
                                к выбору топлива. Уголь оказался отличным
                                вариантом!{' '}
                            </p>
                            <p>
                                Я выбрала уголь марки ДО орешек, который легко
                                закладываются в котел. Он долго горит, и мне не
                                приходится часто бегать к котлу, что очень
                                удобно.
                            </p>
                            <p>
                                {' '}
                                В целом, я остался доволен покупкой. Уголь
                                высокого качества, и я бы рекомендовал его тем,
                                кто ищет надежное решение для отопления.
                            </p>
                            <p>
                                {' '}
                                Также стоит отметить, что цена была более чем
                                приемлемой, особенно с учетом качества. Буду
                                закупаться еще, если возникнет необходимость!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="reviewItem">
                    <div className="reviewItemImg">
                        <img
                            width={280}
                            src="./img/saler3.png"
                            alt="отзыв первого покупателя"
                        />
                    </div>
                    <div className="reviewItemText">
                        <h3>Егор Михайлович</h3>
                        <div className="reviewItemText__text">
                            <p>
                                Купил уголь в местной компании, и хочу
                                поделиться впечатлениями. Покупка прошла гладко.
                                Работники были вежливы и быстро ответили на все
                                мои вопросы. Я также уточнил про льготы для
                                ветеранов — и, к счастью, они предоставляют
                                скидки для нашей категории граждан. Это тоже
                                огромный плюс!{' '}
                            </p>
                            <p>
                                Уголь отличного качества, быстро разгорелся, и
                                тепло в доме стало ощущаться чуть ли не сразу.
                                Он хорошо горит и дает необходимое тепло, что
                                очень важно в наши зимние месяцы.
                            </p>
                            <p>
                                Особенно приятно, что есть компании, которые
                                поддерживают ветеранов и понимают важность
                                моральной и материальной поддержки. Надеюсь, что
                                они и дальше будут продолжать эту практику.
                                Рекомендую всем, кто ищет качественный уголь и
                                хочет ощутить поддержку от производителя
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review
