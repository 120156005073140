import React from 'react'
import ButtonMinusPlus from './ButtonMinusPlus'

function Card({ index, imgUrl, name, price, quantity }) {
    return (
        <div key={index} className="card">
            <img src={imgUrl} alt="" />
            <div className="cardDesc">
                <h3>{name}</h3>
                <p>Цена: {price} руб.</p>
            </div>
            <div className="cardCalc">
                {price > 1000 ? (
                    <p>Выберете вес в тоннах</p>
                ) : (
                    <p>Выберете количество мешков</p>
                )}
                <ButtonMinusPlus quantity={quantity} index={index} />
                {quantity !== 0 && (
                    <p>Стоимость составит {quantity * price} руб.</p>
                )}
            </div>
        </div>
    )
}
export default Card
