import useStore from '../store'

function ButtonMinusPlus({ quantity, index }) {
    const { deleteProducts, increaseQuantity, decreaseQuantity } = useStore()
    const handleAddProduct = product => {
        increaseQuantity(product)
    }
    const handleRemoveProduct = product => {
        decreaseQuantity(product)
    }
    return (
        <div className="wrapperButtonCalc">
            <button
                className={!quantity ? 'buttonDis buttonCalc' : 'buttonCalc'}
                disabled={quantity <= 0}
                onClick={() => handleRemoveProduct(index)}
            >
                -
            </button>
            <span>{quantity}</span>
            <button
                className="buttonCalc"
                onClick={() => handleAddProduct(index)}
            >
                +
            </button>
        </div>
    )
}
export default ButtonMinusPlus
